import React from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import get from 'lodash/get'
import { GatsbyImage } from 'gatsby-plugin-image'

import Layout from '../components/layout'
import Body from '../components/body'
import ContactForm from '../components/contact'

const Page = ({ data }) => {
  const { title, body, displayContactForm } = data.contentfulPage
  const { raw, references } = body || {}

  return (
    <Layout heroProps={{ title: title }}>
      <Helmet title={title} />
      {raw && <Body content={JSON.parse(raw)} references={references} />}
      {displayContactForm && <ContactForm />}
    </Layout>
  )
}

export default Page

export const pageQuery = graphql`
  query PageByTitle($title: String!) {
    contentfulPage(title: { eq: $title }) {
      title
      body {
        raw
        references {
          ... on ContentfulAsset {
            contentful_id
            __typename
            publicUrl
          }
          gatsbyImageData
        }
      }
      displayContactForm
    }
  }
`
