import React from 'react'
import styled from '@emotion/styled'

const Wrapper = styled.div`
  padding: 30px;
  font-size: 16px;
`

const Form = styled.form`
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
`

const Label = styled.label`
  display: block;
  margin: 0 0 10px;
`

const Text = styled.span`
  display: inline-block;
  padding: 0 5px;
`

const Input = styled.input`
  border: 1px solid #ddd;
  background: white;
  padding: 5px;
  display: block;
  outline: none;
  font-size: 14px;
  width: 100%;

  :focus,
  :hover {
    border: 1px solid #ccc;
  }

  &[type='submit'] {
    cursor: pointer;
  }
`

export default ({ content }) => (
  <Wrapper>
    <Form
      method="post"
      action="#"
      name="contact"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
    >
      <input type="hidden" name="bot-field" />
      <Label>
        <Text>Name</Text>
        <Input type="text" name="Name" required />
      </Label>
      <Label>
        <Text>Email</Text>
        <Input type="email" name="Email" required />
      </Label>
      <Label>
        <Text>Subject</Text>
        <Input type="text" name="Subject" required />
      </Label>
      <Label>
        <Text>Message</Text>
        <Input as="textarea" name="Message" rows="10" />
      </Label>
      <Input type="submit" value="Send" />
    </Form>
  </Wrapper>
)
